/**
 * Fallback of the EIC.send or FLIC.send functionality for ALICE
 * generally works only:
 * - if the page is in an iFrame
 * - if EIC v1 (EIC-Heike), EIC v2 or FLIC doesn't exist
 *
 * special feature:
 * - by default, EIC1 will be integrated BUT will be overwritten if data-eic1-send, data-eic2-send or data-flic-send is defined as an attribute of a button element
 * - postMessage could be sent via the mentioned attribute BUT its value has to be in form of `"command=postMessage"` e.g.: `data-eic1-send="command=postMessage"`
 *
 */
(function() {
    // do nothing if the page isn't in an iFrame
    if (window.location === window.parent.location) { return; }
    // window is the iframe and parent is the parent window
    const parent = window.parent;

    // do nothing if EIC or FLIC exists on the page; or there is an iframe in this iframe again (e.g. iframe with payment template)
    let scriptEIC1 = document.querySelector('script[src$="/eic/eic.js"]') || document.querySelector('script[src$="/eic/eic-heike.js"]') || null;
    if (scriptEIC1 && scriptEIC1.parentNode.getAttribute('data-cc') === 'iframe') { scriptEIC1 = null; }
    let scriptEIC2 = document.querySelector('script[src$="/eic/2/eic.js"]') || null;
    if (scriptEIC2 && scriptEIC2.parentNode.getAttribute('data-cc') === 'iframe') { scriptEIC2 = null; }
    let scriptFLIC = document.querySelector('script[src$="/flic.js"]') || null;
    if (scriptFLIC && scriptFLIC.parentNode.getAttribute('data-cc') === 'iframe') { scriptFLIC = null; }
    if (scriptEIC1 || scriptEIC2 || scriptFLIC) { return; }

    // initial the library related info
    const Library = {
        name: '',
        commandPrefix: '',
        source: '',
        inlineScript: ''
    };

    // force to case EIC1 if element with attribute `data-[eic2|flic]-send` doesn't exist on the page
    // EIC2
    if (!!document.querySelector('[data-eic2-send]')) {
        Library.name = 'eic2';
        Library.commandPrefix = 'eic';
        Library.source = 'https://js.ui-portal.de/c/eic/2/eic.js';
        Library.inlineScript = "(function() { function resizeIframe() { if((eic=(((com||{}).unitedinternet)||{}).eic)){ eic.send('resize='+document.body.offsetHeight+'x100per','', window.parent); } } window.addEventListener('load', resizeIframe, false); window.addEventListener('resize', resizeIframe, false); })();";
    // FLIC
    } else if (!!document.querySelector('[data-flic-send]')) {
        Library.name = 'flic';
        Library.commandPrefix = 'flic';
        Library.source = 'https://js.ui-portal.de/flic/default/flic.js';
        Library.inlineScript = "(function() { function resize() { setTimeout(() => { FLIC.send('resize', '100%'+'x'+document.body.offsetHeight, parent); }, 100); } window.addEventListener('load', resize); window.addEventListener('resize', resize); })();";
    // by default: EIC1 (EIC-Heike)
    } else {
        Library.name = 'eic1';
        Library.commandPrefix = 'com.unitedinternet.eic';
        Library.source = 'https://js.ui-portal.de/c/eic/eic.js';
        Library.inlineScript = "(function() { var lastHeight; function resizeIframe() { var container = document.querySelector('div.grid'); var height = container.offsetHeight; if (height === lastHeight) { return; } com.unitedinternet.eic.send('resize=' + height); lastHeight = height; } window.addEventListener('load', resizeIframe, false); window.addEventListener('resize', resizeIframe, false); })();";
    }

    // Append Library & inline script to document.head for having all basic functionalities
    let script = document.createElement('script');
    script.setAttribute('src', Library.source);
    document.head.appendChild(script);
    let inlineScript = document.createElement('script');
    inlineScript.innerHTML = Library.inlineScript;
    document.head.appendChild(inlineScript);

    // stop further actions if no further postMessage needed
    const attrName = 'data-' + Library.name + '-send';
    const elsLibrarySend = document.querySelectorAll('[' + attrName + ']');
    if (elsLibrarySend.length < 1) { return; }

    // add event listener to all buttons which have the special attribute directly or which are in the speical components
    // event function is to send the message (value of the attribute) to the parent window
    for (let i = 0, l = elsLibrarySend.length; i < l; i++) {
        const keyBtn = elsLibrarySend[i].classList.contains('button') ? elsLibrarySend[i] : elsLibrarySend[i].querySelector('.button') || null;
        if (!keyBtn) { continue; }

        const attrValue = elsLibrarySend[i].getAttribute(attrName) || '';
        keyBtn.addEventListener('click', function() {
            try {
                // get the target library through the nested window object e.g.: window.com.unitedinternet.eic
                const lib = retrieveProp(Library.commandPrefix.split('.'), window);
                switch (Library.name) {
                    case 'eic1':
                    case 'eic2':
                        lib.send(attrValue, '', parent);
                        break;
                    case 'flic':
                        const [commandKey, commandValue] = decomposeCommand(attrValue);
                        lib.send(commandKey, commandValue, parent)
                        break;
                    default:
                        console.log('No EIC1, EIC2 or FLIC is using.')
                }
            } catch (error) {
                console.log(error);
            }
        });
    }

    /**
     * decompose commandKey and commandValue
     * @param {string} command post message in form of `command=message`
     */
    function decomposeCommand(command) {
        const index = command.indexOf('=');
        // the command should contain at least three chars and the index of "=" should be at least 1
        return command.length < 3 || index < 1 ? [null, null]
                : [command.substring(0, index), command.substring(index + 1)]
    }

    /**
     * get property through a nested object
     * @param {array} path path to the property
     * @param {object} object the given nested object
     */
    function retrieveProp(path, object) {
        return path.reduce((obj, path) => (obj || {})[path], object);
    }
})();
