(function() {
  window.addEventListener('load', function(evt) {
      // This array is needed for comparison with the generated array of 
      // the current window location to be sure that the following adjustment
      // is only made on this specific page which ends with `/deals/advent`
      const xmasLocArray = ['advent', 'deals']
      const winLocArray = location.pathname.split('/').filter(Boolean).reverse().splice(0, xmasLocArray.length)
        
      const equals = (a, b) =>
          a.length === b.length &&
          a.every((v, i) => v === b[i])

      if (equals(xmasLocArray, winLocArray)) {
        document.getElementsByTagName('body')[0].setAttribute('data-theme', 'advent')
      }
  });
})();
