(function() {
    window.addEventListener('load', function(evt) {
        if(window.location.href.indexOf('homepage-mail') > -1) {
            const domainInput = document.getElementById('domain_name');

            if(!domainInput || !domainInput.dataset.mdhPromoTld) { return; }

            const promoteTLD = domainInput.dataset.mdhPromoTld;
            const domainInputHidden = domainInput.cloneNode(true);
            domainInputHidden.id = 'domain_name_hidden';
            domainInputHidden.name = domainInput.name;
            domainInputHidden.classList.add("hidden");

            domainInput.name = '';
            domainInput.after(domainInputHidden);

            const onInputChange = function(evt) {
                domainInputHidden.value = processInput(evt.target.value) + promoteTLD;
            };

            const processInput = function(value) {
                let splitValues = value.split('.');

                // Check whether the last entry is empty - if so, it will be removed
                if (splitValues[splitValues.length - 1] === '') {
                    splitValues.pop();
                }

                // Check that the entry before the last point is not 'www' - if so, the entry after the point is removed
                if (splitValues.length > 1 && splitValues[splitValues.length - 2] != 'www') {
                    splitValues.pop();
                }
                return splitValues.join('.');
            };

            domainInput.addEventListener('input', onInputChange);
        }
    });
})();
